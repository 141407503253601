import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const laspca = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close Laspca"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap Laspca-modal">
				<ul className="Work-slides">
					<li class="spacer"></li>
					<li className="Title">
						<h3 className="font-title text-center">Louisiana LASPCA</h3>
					</li>
					<li className="Work">
						<div>
							<picture>
								<source srcset={'/work/spca/1/1-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/spca/1/1-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/spca/1/1-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/spca/1/1-a.png"} alt="LASPCA Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work">
					<div>
							<picture >
								<source srcset={'/work/spca/2/2-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/spca/2/2-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/spca/2/2-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/spca/2/2-a.png"} alt="LASPCA Work Example Pet search" />
							</picture>
						</div>
					</li>
					<li className="Work laspca-final">
						<div className="laspca-goto goto">
							<div>
								<img src={'/work/spca/laspca.gif'} alt="LASPCA Logo" />
								<p>The Louisiana SPCA is a 501(c)(3) nonprofit organization dedicated to promote, protect and advance the well-being of Louisiana’s companion animals.</p>
								<a target="blank" rel="noreferrer" href="https://www.louisianaspca.org/"><p>View Site</p></a>
							</div>
						</div>
					</li>
				</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default laspca